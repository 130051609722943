import {
  IM,
  IMLayout,
  IMStyle,
  SpacingProps,
  useDimensions,
  useEvent,
  useLanguage,
  useModalController,
  useTheme,
} from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import api from '../../../apis/apiCalls'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import CellText from '../../../components/CellText'
import DynamicView from '../../../components/Infominds/DynamicView'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../../components/Infominds/Pressable'
import Separator from '../../../components/Infominds/Separator'
import NavigationIcon from '../../../components/NavigationIcon'
import SkeletonText from '../../../components/skeleton/SkeletonText'
import Text from '../../../components/Text'
import TextTable from '../../../components/TextTable'
import { EDIT_ARTICLE_COUNTERS_EVENT_KEY } from '../../../constants/EmitterKeys'
import { REQUEST_SERIAL_NUMBER_COUNTERS } from '../../../constants/Keys'
import TicketArticleCounterModal from '../../../modals/tickets/TicketArticleCounterModal'
import { TicketStackParamList } from '../../../navigation/types'
import { ThemeColorExpanded, TicketArticleCounterParams } from '../../../types'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  activityId: string | undefined
  serialNumberId: string
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const firstColumn = IMLayout.flex.f2
const secondColumn = IMLayout.flex.f2
const thirdColumn = IMLayout.flex.f3
const fourthColumn = IMLayout.flex.f2
const fifthColum = IMLayout.flex.f1
const sixthColum = { width: 18 }

const TicketArticleCounterReadingCard = memo(function TicketArticleCounterReadingCard({ activityId, serialNumberId, spacing, style }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const controller = useModalController<TicketArticleCounterParams>()

  useEvent({ key: EDIT_ARTICLE_COUNTERS_EVENT_KEY }, () => refresh())

  const { item: counters, loadItem, loading } = useControlledLoader(api.getSerialNumberCounter, { id: REQUEST_SERIAL_NUMBER_COUNTERS })

  useEffect(() => {
    loadItem({ serialnumberId: serialNumberId })
  }, [serialNumberId])

  const refresh = () => loadItem({ serialnumberId: serialNumberId })

  const description = (value?: string) => <TextTable text={value ?? ''} />

  const lastMonitoring = (data?: string) => <TextTable text={data ? TimeUtils.format(data, language) : '-'} />

  const contractIcon = (contract: boolean) => (
    <IM.Icon
      icon={['fas', contract ? 'circle-check' : 'circle-xmark']}
      color={contract ? theme.general.info : theme.general.error}
      style={IMLayout.flex.f1}
    />
  )

  const counterValue = (value?: number, formula?: string) => (
    <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
      {isSmallDevice && formulaIcon(formula, 'right')}
      <Text style={styles.text}>{value ?? '-'}</Text>
      {!isSmallDevice && formulaIcon(formula, 'left')}
    </IM.View>
  )

  const formulaIcon = (formula?: string, elemSpacing?: SpacingProps) => (
    <>{formula && <IM.Icon icon={['fal', 'function']} color={theme.textDetail} spacing={elemSpacing} />}</>
  )

  return (
    <>
      <IM.Card head={<CardLeftTitle text={i18n.t('COUNTERS_READING')} />} spacing={spacing} style={[styles.container, style]} noContentSpacing>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <IM.View style={{ margin: isSmallDevice || counters?.length === 0 ? 6 : 10 }}>
          {loading === false && counters !== undefined && counters.length !== 0 ? (
            <>
              {!isSmallDevice && (
                <IM.View>
                  <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                    <CellText style={firstColumn} secondary numberOfLines={1} value={i18n.t('TYPE')} />
                    <CellText style={secondColumn} secondary numberOfLines={1} value={i18n.t('DESCRIPTION')} />
                    <CellText style={thirdColumn} secondary numberOfLines={1} value={i18n.t('LAST_MONITORING')} />
                    <CellText style={fourthColumn} secondary numberOfLines={1} value={i18n.t('COUNTER_VALUE')} />
                    <CellText style={fifthColum} secondary numberOfLines={1} value={i18n.t('CONTRACT')} />
                    <IM.View style={sixthColum} />
                  </DynamicView>
                  <Separator spacing="none" type="primary" />
                </IM.View>
              )}
              {counters?.map((data, index) => (
                <IM.View key={data.id}>
                  <Pressable
                    onPress={() =>
                      isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                        ? navigation.navigate('TicketArticleCounter', { activityId, counters, focus: index })
                        : controller.show({ activityId, counters, focus: index })
                    }>
                    <IM.View style={IMLayout.flex.row}>
                      <DynamicView style={IMLayout.flex.f1} flexLimitSmallDevice={2}>
                        {isSmallDevice ? (
                          <IM.View style={IMLayout.flex.f1}>
                            <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{data.counterType}</IM.Text>
                          </IM.View>
                        ) : (
                          <CellText style={firstColumn} value={data.counterType} />
                        )}
                        <CellText title={i18n.t('DESCRIPTION')} style={secondColumn} value={description(data.description)} />
                        <CellText title={i18n.t('LAST_MONITORING')} style={thirdColumn} value={lastMonitoring(data.movementDate)} />
                        <CellText title={i18n.t('VALUE')} style={fourthColumn} value={counterValue(data.movementValue, data.calculationFormula)} />
                        <CellText title={i18n.t('CONTRACT')} style={fifthColum} value={contractIcon(data.contract)} />
                        {!isSmallDevice && <CellText style={sixthColum} value={<NavigationIcon />} />}
                      </DynamicView>
                      {isSmallDevice && <NavigationIcon enableTableStyle />}
                    </IM.View>
                  </Pressable>
                  {index !== counters?.length - 1 && <Separator spacing="none" />}
                </IM.View>
              ))}
            </>
          ) : (
            <>
              {counters === undefined ? (
                <IM.View spacing={isSmallDevice ? 'horizontal' : 'none'}>
                  {isSmallDevice ? (
                    <>
                      <SkeletonText width="80%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="vertical" />
                    </>
                  ) : (
                    <>
                      <SkeletonText width="100%" spacing="top" />
                      <Separator spacing="bottom" />
                      <SkeletonText width="100%" spacing="bottom" />
                    </>
                  )}
                </IM.View>
              ) : (
                <IM.View spacing="all">
                  <IM.Text secondary>{i18n.t('NO_COUNTER_FOUND')}</IM.Text>
                </IM.View>
              )}
            </>
          )}
        </IM.View>
      </IM.Card>
      <TicketArticleCounterModal controller={controller} />
    </>
  )
})

export default TicketArticleCounterReadingCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
  text: {
    flexShrink: 1,
  },
})
