import { Language, useDidUpdate, useLanguage, useTheme } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { TextStyle } from 'react-native'
import { formatWithMask, MaskArray } from 'react-native-mask-input'

import useForm from '../../hooks/useForm'
import { DateInputRef } from '../../types'
import TimeUtils from '../../utils/TimeUtils'
import DateTimePicker from '../Infominds/DateTimePicker'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type TextInputProps = Omit<BaseTextInputProps, 'onChangeText'> &
  Omit<BaseTextInputProviderProps, 'error'> & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    minimumDate?: Date
    maximumDate?: Date
    onChangeDate: ((date: Date | undefined) => void) | undefined
    onShow?: (value: boolean) => void
  }

export const convertDateToInputFormat = (date: Date, language: Language) => {
  return TimeUtils.format(date.toISOString(), language)
}

export const convertIsoDateFromInputFormat = (date: string) => {
  const newDate = dayjs(date)

  const day = newDate.get('D')
  const dayString = day < 10 ? `0${day}` : day.toString()

  const month = newDate.get('M') + 1
  const monthString = month < 10 ? `0${month}` : month.toString()

  return `${dayString}-${monthString}-${newDate.get('y')}`
}

export const convertDateFromInputFormat = (date: string) => {
  const [d, m, y] = date.split(/[T :\-/.,]/g)

  return new Date(`${y}-${m}-${d}`)
}

const dateMask = (value = '', language: Language): MaskArray => {
  const separator = language === 'de' ? '.' : '/'
  const cleanText = value.replace(/\D+/g, '')

  let secondDigitDayMask = /\d/

  if (cleanText.charAt(0) === '0') {
    secondDigitDayMask = /[1-9]/
  }
  if (cleanText.charAt(0) === '3') {
    secondDigitDayMask = /[01]/
  }

  let secondDigitMonthMask = /\d/

  if (cleanText.charAt(2) === '0') {
    secondDigitMonthMask = /[1-9]/
  }
  if (cleanText.charAt(2) === '1') {
    secondDigitMonthMask = /[012]/
  }

  return [/[0-3]/, secondDigitDayMask, separator, /[0-1]/, secondDigitMonthMask, separator, /\d/, /\d/, /\d/, /\d/]
}

const DateInput = memo(
  forwardRef(function DateInput(
    {
      title,
      details,
      titleFontWeight,
      editable = true,
      loading,
      disableFocus,
      minimumDate = new Date(1899, 0, 1),
      maximumDate = new Date(2199, 0, 1),
      onChangeDate,
      onShow,
      ...textInputProps
    }: TextInputProps,
    ref: ForwardedRef<DateInputRef>
  ) {
    useImperativeHandle(ref, () => ({
      reset: date => setText(prev => ({ ...prev, value: convertDateToInputFormat(date, language) })),
    }))

    const { theme } = useTheme()
    const { setError } = useForm()
    const { language } = useLanguage()

    const [show, setShow] = useState(false)
    const [formError, setFormError] = useState(false)
    const [text, setText] = useState<{ value: string | undefined; autoComplete: boolean }>({ value: textInputProps.value, autoComplete: true })
    const prevText = useRef(text.value)

    const { masked } = formatWithMask({
      text: text.value,
      mask: val => dateMask(val, language),
      maskAutoComplete: text.autoComplete,
    })

    useEffect(() => {
      onShow?.(show)
    }, [show])

    useDidUpdate(() => {
      if (!text.value) {
        handleError(false)
        onChangeDate?.(undefined)
        return
      }

      const limitedDate = TimeUtils.manageDateLimit(
        TimeUtils.manageDateLimit(convertDateFromInputFormat(text.value), maximumDate, 'max'),
        minimumDate,
        'min'
      )

      limitedDate.setHours(0, 0, 0, 0)

      if (text.value.length === 10) {
        if (!dayjs(limitedDate).isSame(dayjs(convertDateFromInputFormat(text.value)))) {
          setText(prev => ({ ...prev, value: convertDateToInputFormat(limitedDate, language) }))
        }
        handleError(false)
        onChangeDate?.(limitedDate)
      } else if (text.value?.length === 0) {
        handleError(false)
        onChangeDate?.(undefined)
      }
    }, [text.value])

    const handlePickerDate = (newDate: Date) => {
      setText(prev => ({ ...prev, value: convertDateToInputFormat(newDate, language) }))
    }

    const handleError = (value: boolean) => {
      setError(value)
      setFormError(value)
    }

    const data = useMemo(() => (text.value?.length === 10 ? convertDateFromInputFormat(text.value) : new Date()), [text.value])

    return (
      <>
        <BaseTextInputProvider editable={editable} error={formError} loading={loading} disableFocus={disableFocus}>
          <BaseTextInput
            {...textInputProps}
            value={text.autoComplete ? masked : text.value?.length === 10 ? dayjs(text.value).locale(language).format('L') : text.value}
            onChangeText={newText => {
              let autoComplete = true

              if (prevText.current !== undefined && newText.length <= prevText.current.length) {
                autoComplete = false
              }

              prevText.current = newText
              setText({ value: newText, autoComplete })
            }}
            type="date"
            placeholder={dayjs('1990-01-01').locale(language).format('L')}
            pointerEvents="box-only"
            onBlur={() => {
              if (text.value && text.value.length < 10 && text.value.length > 0) {
                handleError(true)
              } else {
                handleError(false)
              }
            }}>
            {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}
            <BaseTextInput.RightIcon>
              <PressableIcon icon={['fal', 'calendar']} color={theme.textDetail} size={20} onPress={() => setShow(true)} disabled={!editable} />
            </BaseTextInput.RightIcon>
          </BaseTextInput>
        </BaseTextInputProvider>
        <DateTimePicker
          date={data}
          setDate={handlePickerDate}
          show={show}
          setShow={setShow}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          mode="date"
        />
      </>
    )
  })
)

export default DateInput
